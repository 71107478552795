<template>
  <v-container>
    <v-sheet elevation="0" class="pa-2 mt-3">
      <v-data-table
        :headers="headers[$vuetify.breakpoint.xsOnly]"
        :items="items"
        :items-per-page="10"
        item-key="code"
        sort-by="issue_date"
        mobile-breakpoint="10"
        :search="search"
        :loading="loading_status"
        loader-height="10"
        loading-text="Cargando ..."
        @click:row="handleClick"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Clientes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn fab dark small color="info" class="mr-3" to="ClientsMap">
              <v-icon>mdi-map-marker-radius</v-icon>
            </v-btn>
            <v-btn fab dark small color="success" @click="newForm">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="search" label="Buscar" class="mx-4" />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
          <span v-if="$vuetify.breakpoint.xsOnly">
            <br />
            <small>{{ item.issue_date }}</small>
          </span>
        </template>
        <template v-slot:[`item.note`]="{ item }">
          <span v-html="item.note"></span>
        </template>
        <template v-slot:[`item.city`]="{ item }">
          <span v-if="!$vuetify.breakpoint.xsOnly">
            {{ item.city }}
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="handleClick(item)">
            mdi-account-edit-outline
          </v-icon>

          <v-icon small class="mr-2" @click="newOrder(item)">
            mdi-file-document-plus-outline
          </v-icon>
        </template>
      </v-data-table>
    </v-sheet>
    <PartyForm
      :table="table"
      :item="item"
      :dialog="dialog"
      :newItem="newItem"
      @close="dialog = false"
      @refresh="refresh()"
      @newOrder="newOrder(item)"
    />

    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando ...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver, getIssueDate, getToday } from "../../services/webserver.js";
import createItem from "../../utils/createParty.js";
import PartyForm from "../../components/PartyForm.vue";

export default {
  components: { PartyForm },
  mounted() {
    this.get_items();
  },
  data() {
    return {
      chosenPhoto: null,
      table: "clients",
      validMsg: false,
      broadcast: { email: null, subject: null, msg: null },
      broadcast_dialog: false,
      customToolbar: [
        [{ font: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ header: 1 }, { header: 2 }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
      dialog: false,
      newItem: false,
      search: "",
      loading_status: false,
      errors: [],
      items: [],
      item: createItem(),
      headers: {
        false: [
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "Grupo",
            align: "start",
            sortable: true,
            value: "group_name",
            dataType: "text",
          },
          {
            text: "Dirección",
            align: "start",
            sortable: true,
            value: "address",
            dataType: "text",
          },
          {
            text: "Ciudad",
            align: "start",
            sortable: true,
            value: "city",
            dataType: "text",
          },
          {
            text: "Note",
            align: "start",
            sortable: true,
            value: "note",
            dataType: "text",
            width: "30%",
          },
          {
            text: "Fecha",
            align: "start",
            sortable: true,
            value: "issue_date",
            dataType: "text",
          },
          {
            text: "Teléfono",
            align: "end",
            sortable: true,
            value: "mobile",
            dataType: "text",
          },
        ],
        true: [
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "",
            align: "start",
            sortable: true,
            value: "city",
            dataType: "text",
          },
          {
            text: "Teléfono",
            align: "end",
            sortable: true,
            value: "mobile",
            dataType: "text",
          },
        ],
      },
      issueDateFrom: getIssueDate(5),
      issueDateTo: getToday(),
      groupby: "",
      groups: [],
    };
  },
  methods: {
    PhotoToBase64() {
      if (!this.chosenPhoto) {
        this.data = "No File Chosen";
      }

      this.getBase64(this.chosenPhoto).then((data) => {
        var qry = {
          account: this.$store.state.profile.account,
          banner: data,
          filename: this.chosenPhoto.name,
        };

        webserver("upload_banner", qry, (data) => {
          this.loading_status = false;
          this.broadcast.msg =
            "<img src='https://mayorista.app/banners/" + data + "'></img>";
        });
        //this.broadcast.msg = "<img src='" + data + "'></img>";
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    newOrder(e) {
      this.$router.push({ name: "Inicio", params: e });
    },
    handleClick(data) {
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    updateLocation(e) {
      this.store.location = JSON.stringify(e);
    },
    changeClients() {
      this.get_items();
    },
    get_items() {
      var qry = {
        account: this.$store.state.profile.account,
        table: this.table,
        filters: [
          { field: "account", operator: "=", value: this.$store.state.profile.account },
        ],
      };
      // if (this.groupby === "") {
      //   qry.groupby = "group_name";
      // } else {
      //   qry.filters.push({
      //     field: "group_name",
      //     operator: " IN ",
      //     value: "'" + this.groupby + "',''",
      //   });
      // }

      this.loading_status = true;
      webserver("get_dataTable", qry, (data) => {
        this.loading_status = false;
        this.items = data;
        // if (this.groupby === "") {
        //   this.groups = data;
        // } else {
        //   this.items = data;
        //   if (data.length > 0) {
        //     this.items = data;
        //   } else {
        //     this.newForm();
        //   }
        // }
      });
    },
    refresh() {
      this.dialog = false;
      this.get_items();
    },
    getImagePhoto: function (account, code, src) {
      var currentdate = new Date();
      var url =
        "https://" +
        this.$store.getters.getHost +
        "/" +
        account +
        "/clients/" +
        src +
        "?" +
        currentdate;
      return url;
    },
    newForm() {
      this.item = createItem();
      this.item.account = this.$store.state.profile.account;
      this.item.store = this.$store.state.company.code;
      this.valid = false;
      this.newItem = true;
      this.dialog = true;
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
